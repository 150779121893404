import React, { Component } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";
import { PageWrapper } from "../components/page-wrapper";
import { Footer } from "../components/footer";
import { Inner } from "../atoms/inner";
import styled from "styled-components";

const LinkWrapper = styled.div`
  margin-bottom: 15px;
`;

const UpperWrapper = styled.div`
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;

  h2 {
    margin-bottom: 5px;
  }
`;
export class ErrorPage extends Component {
  render() {
    return (
      <div>
        <PageWrapper>
          <Inner>
            <UpperWrapper>
              <h2>Oops! We can't find the page you're looking for.</h2>
              <p>
                Start your search for the ultimate remote work with the links
                below:
              </p>
            </UpperWrapper>
            <LinkWrapper>
              <Link to={paths.home}>Homepage</Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to={paths.developer_jobs}>Developer Jobs</Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to={paths.marketing_jobs}>Marketing Jobs</Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to={paths.manager_jobs}>Manager/Exec Jobs</Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to={paths.design_jobs}>Design Jobs</Link>
            </LinkWrapper>
          </Inner>
        </PageWrapper>
        <Footer />
      </div>
    );
  }
}

export default ErrorPage;
