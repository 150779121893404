import React from "react";
import styled from "styled-components";
import { media } from "../../../constants/theme";

const StyledInner = styled.div`
  padding: 20px;

  ${media.desktop`
    padding: 30px;
  `}
`;

export const Inner = props => {
  return <StyledInner>{props.children}</StyledInner>;
};
